<template>
  <div class="single_poster">
    <van-nav-bar
      title="晒单海报"
      @click-left="$router.go(-1)"
      :fixed="true"
      :border="false"
    >
      <template #left>
        <svg-icon icon-class="close" />
      </template>
    </van-nav-bar>
    <div class="background_box" ref="background_box">
      <div class="content" ref="order">
        <div class="top">
          <img
            src="@/assets/images/single_oster_success.png"
            alt=""
            class="success"
          />
          <div class="deal_number">
            <h2>下单回执</h2>
            <p>订单编号：{{ orderInfo.dealNumber }}</p>
            <p>下单时间：{{ orderInfo.createTime }}</p>
          </div>
        </div>
        <img src="@/assets/images/single_oster_line.png" alt="" class="line" />
        <main>
          <div class="title">
            <h3>
              <img src="@/assets/images/logo.png" alt="" width="24" />
              正品汇商城
            </h3>
            <p>私域流量的人、电子保单的货、联盟链上的场</p>
          </div>
          <div class="order">
            <ul class="user">
              <li>
                <span>{{ orderInfo.freeShipping ? "收货人" : "提货人" }}</span>
                <p>{{ orderInfo.consignee }} {{ orderInfo.telephone }}</p>
              </li>
              <li v-if="orderInfo.freeShipping">
                <span>收货地址</span>
                <p>
                  {{ orderInfo.province }}{{ orderInfo.city
                  }}{{ orderInfo.county }}***************
                </p>
              </li>
            </ul>
            <div class="goods" v-if="goodsLoading">
              <div
                class="goods_box"
                v-for="(item, index) in orderInfo.orderCommodityListItems"
                :key="index"
              >
                <img :src="item.filePath" />
                <div class="goods_details">
                  <p class="goods_name">{{ item.commodityName }}</p>

                  <div>
                    <!-- {{ item.specsParameter }}&emsp; -->
                    <span class="specification">×{{ item.quantity }}</span>
                    <span class="unit_price"
                      >单价：¥{{
                        (item.sellingPrice / item.quantity).toFixed(2)
                      }}</span
                    >
                    <span class="actual_payment"
                      >实付：¥{{ item.actualPayment.toFixed(2) }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <van-loading color="#5096ff" v-else />
            <ul class="totalAmount">
              <li>
                <span>商品数量</span>
                <p>×{{ goodsQuantity }}</p>
              </li>
              <li>
                <span>商品金额</span>
                <p>￥{{ totalAmount.toFixed(2) }}</p>
              </li>
              <li v-show="integral || bankIntegral">
                <span>积分抵扣</span>
                <p>抵扣{{ (integral + bankIntegral).toFixed(2) }}元</p>
              </li>
              <li>
                <span>合计付款</span>
                <h4 style="color: #fb4e10">
                  ￥{{ (totalAmount - integral - bankIntegral).toFixed(2) }}
                </h4>
              </li>
            </ul>
          </div>
          <!-- <div class="order_footer"></div> -->
        </main>
      </div>
    </div>

    <footer class="van-hairline--top">
      <van-button
        round
        v-show="total > 1"
        v-for="(item, i) in 2"
        :key="i"
        @click="changePages(i)"
        >{{ i ? "下一页" : "上一页" }}</van-button
      >
      <van-button round color="#009A4D" @click="convert">保存海报</van-button>
    </footer>
    <van-overlay :show="show" z-index="1000" :lock-scroll="false">
      <van-loading v-show="loading" color="#fff" />
      <div class="img_box" v-show="!loading" ref="img_box">
        <img
          :src="imgSrc[pageNum - 1].src"
          alt=""
          @load="loading = false"
          crossorigin="anonymous"
        />
      </div>
      <div class="bottom">
        <p>长按图片保存至相册</p>
        <div class="close" @click="show = false">
          <van-icon name="cross" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { imgUrlToBase64, formatDate } from "@/utils/validate";
import { setToken } from "@/utils/auth";

export default {
  name: "single-poster",

  data() {
    return {
      show: false,
      loading: false,
      goodsLoading: false,
      imgSrc: [{ src: "" }],
      orderInfo: {},
      TotalOrderInfo: [],
      pageNum: 1,
      total: 1,
      goodsQuantity: 0,
      totalAmount: 0,
      integral: 0,
      bankIntegral: 0,
      topImg: "",
    };
  },
  created() {
    if (this.$route.query.token) setToken(this.$route.query.token);
    if (this.$route.query.dealNumber) {
      this.getDealDetails();
    } else {
      this.getTotalDealDetails();
    }
  },
  watch: {
    orderInfo(val, oldVal) {
      // this.goodsLoading = false;
      this.totalAmount = 0;
      this.integral = 0;
      this.bankIntegral = 0;
      this.goodsQuantity = 0;
      let _ = val;
      if (_.consignee.length < 3) {
        _.consignee = _.consignee.substr(0, 1) + " *";
      } else {
        _.consignee = _.consignee.substr(0, 1) + "**";
      }
      _.createTime = formatDate(_.createTime);
      _.telephone = _.telephone.substr(0, 3) + "****" + _.telephone.substr(7);
      _.orderCommodityListItems.forEach((goods) => {
        this.totalAmount += goods.sellingPrice;
        this.integral += goods.integral;
        this.bankIntegral += goods.bankIntegral;
        this.goodsQuantity += goods.quantity;
      });

      // _.orderCommodityListItems[0].filePath = imgUrlToBase64(
      //   _.orderCommodityListItems[0].filePath
      // );
    },
    TotalOrderInfo(val) {
      val.forEach((order) => {
        order.orderCommodityListItems.forEach((goods) => {
          goods.filePath = goods.filePath.split(",")[0].endsWith(".mp4")
            ? goods.filePath.split(",")[1]
            : goods.filePath.split(",")[0];
          imgUrlToBase64(goods.filePath).then((res) => {
            goods.filePath = res;
            this.goodsLoading = true;
          });
        });
      });

      this.orderInfo = val[0];
    },
  },
  methods: {
    convert() {
      if (this.imgSrc[this.pageNum - 1].src) {
        //已有缓存的截图
        if (this.$store.state.App) {
          this.saveThePicture();
        } else {
          this.show = true;
          this.loading = false;
        }
      } else {
        //订单图片还没加载完
        clearInterval(this.t);
        if (!this.goodsLoading) {
          this.Toast.loading("订单信息加载中...");
          this.t = setInterval(() => {
            this.convert();
          }, 100);
          return;
        }

        //订单图片加载完
        if (this.$store.state.App) {
          var toast1 = this.Toast.loading({
            message: "加载中...",
            forbidClick: true,
            loadingType: "spinner",
            duration: 0,
          });
        } else {
          this.show = true;
          this.loading = true;
        }
        const options = {
          // width: this.$refs.dom.offsetWidth,
          // height: this.$refs.dom.offsetHeight,
          useCORS: true,
          logging: true,
          backgroundColor: "transparent",
          // scale: 5,
          // dpi: 600,
        };
        html2canvas(this.$refs.order, options)
          .then((canvas) => {
            // box.appendChild(canvas);
            this.imgSrc[this.pageNum - 1].src = canvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");

            if (this.$store.state.App) {
              this.saveThePicture();
              toast1.clear();
            } else {
              this.loading = false;
            }
          })
          .catch((e) => {
            if (this.$store.state.App) {
              toast1.clear();
            } else {
              this.show = false;
              this.loading = false;
            }
            this.Toast.fail("形成海报失败");
          });
      }

      // setTimeout(() => {
      //   html2canvas(this.$refs.order, options).then((canvas) => {
      //     // box.appendChild(canvas);
      //     this.imgSrc[this.pageNum - 1].src = canvas
      //       .toDataURL("image/png")
      //       .replace("image/png", "image/octet-stream");
      //   });
      // }, 500);

      // html2canvas(box, {
      //   allowTaint: true, //允许污染
      //   taintTest: true, //在渲染前测试图片(没整明白有啥用)
      //   useCORS: true, //使用跨域(当allowTaint为true时这段代码没什么用)
      //   background: "pink",
      //   onrendered: function (canvas) {
      //     imgBlob = canvas.toDataURL("image/jpeg", 1.0); //将图片转为base64, 0-1 表示清晰度
      //     imgBlob = imgBlob.toString().substring(imgBlob.indexOf(",") + 1); //截取base64以便上传
      //   },
      // });
    },
    saveThePicture() {
      let data = {
        type: 4,
        data: this.imgSrc[this.pageNum - 1].src,
      };
      Android.callAndroid(JSON.stringify(data));
    },
    async getTotalDealDetails() {
      //totalDealNumber
      const data = await this.$API.order.TotalDealDetails({
        object: {
          totalDealNumber: this.$route.query.totalDealNumber,
        },
      });
      this.TotalOrderInfo = data.data.data.dealDetailsRsps;
      this.total = this.TotalOrderInfo.length;
      for (let i = 0; i < this.total; i++) {
        this.$set(this.imgSrc, i, { src: "" });
      }
    },
    async getDealDetails() {
      const data = await this.$API.order.DealDetails({
        object: {
          dealNumber: this.$route.query.dealNumber,
        },
      });
      this.TotalOrderInfo.push(data.data.data);
      this.$set(this.imgSrc, 0, { src: "" });
    },
    changePages(i) {
      // if (this.timeout) {
      //   clearTimeout(this.timeout);
      // }
      // this.timeout = setTimeout(() => {
      if (i) {
        if (this.pageNum < this.total) {
          this.pageNum++;
          this.orderInfo = this.TotalOrderInfo[this.pageNum - 1];
        } else {
          this.Toast({
            message: "没有更多了",
            position: "bottom",
            duration: 1000,
          });
        }
        //下一页
      } else {
        if (this.pageNum > 1) {
          this.pageNum--;
          this.orderInfo = this.TotalOrderInfo[this.pageNum - 1];
        } else {
          this.Toast({
            message: "已经是第一页了",
            position: "bottom",
            duration: 1000,
          });
        }
        //上一页
      }
      // }, 300);
    },

    //     let link = document.createElement("a");
    // //设置下载的文件名
    // link.download = "报告";
    // link.style.display = "none";
    // //设置下载路径
    // link.href = url;
    // //触发点击
    // document.body.appendChild(link);
    // link.click();
    // //移除节点
    // document.body.removeChild(link);
    // var a = document.createElement("a");
    // a.href = url;
    // a.download = new Date().valueOf() + ".png";
    // var e = document.createEvent("MouseEvents");
    // e.initMouseEvent(
    //   "click",
    //   true,
    //   false,
    //   window,
    //   0,
    //   0,
    //   0,
    //   0,
    //   0,
    //   false,
    //   false,
    //   false,
    //   false,
    //   0,
    //   null
    // );
    // a.dispatchEvent(e);
  },
};
</script>

<style lang="scss" scoped>
.single_poster {
  position: relative;
  height: 100%;
  .van-nav-bar {
    background: #5096ff;
    color: #fff;
    ::v-deep .van-nav-bar__title {
      color: #fff;
    }
  }
  .background_box {
    // height: 276px;
    position: absolute;
    top: 0;
    background: linear-gradient(181deg, #5096ff 20%, #ffffff 35%);

    padding-top: 24px;
    bottom: 50px;
    top: 46px;
    overflow-y: scroll;
    .content {
      background: linear-gradient(181deg, #5096ff 20%, #ffffff 35%);
      padding-top: 10px;
      .top {
        display: flex;
        .success {
          width: 150px;
          height: 95px;
        }
        .deal_number {
          margin: 0 auto;
          padding: 0 10px;
          color: #fff;
          h2 {
            font-size: 24px;
            margin-bottom: 14px;
          }
          p {
            font-size: 12px;
            margin-bottom: 6px;
          }
          p:last-child {
            margin-bottom: 0;
          }
        }
      }
      .line {
        width: 100%;
      }
      main {
        position: relative;
        top: -16px;
        padding: 0 12px 0;
        overflow-y: scroll;
        .title {
          text-align: center;
          // padding: 16px 15px 18px;
          padding: 10px 15px;
          color: #333333;
          background-image: url("../../assets/images/single_oster_bg.png");
          background-size: 100% 100%;
          h3 {
            font-size: 18px;
            margin-bottom: 8px;
            img {
              vertical-align: middle;
              margin-right: 8px;
            }
          }
        }
        .order {
          position: relative;
          top: -1px;
          padding: 0 15px;
          background: linear-gradient(180deg, #ffffff 0%, #ecf4ff 100%);
          border-radius: 0 0 14px 14px;
          .user {
            // padding: 16px 0;
            padding: 8px 0;
            border-top: 1px dashed #d9e5f8;
            border-bottom: 1px dashed #d9e5f8;
            li {
              display: flex;
              justify-content: space-between;
              color: #666666;
              // margin-bottom: 10px;
              margin-bottom: 6px;

              p {
                width: 50%;
                text-align: end;
                color: #333333;
              }
            }
            li:last-child {
              margin-bottom: 0;
            }
          }
          .goods {
            // padding: 20px 0;
            padding: 12px 0;
            position: relative;
            .goods_box {
              display: flex;
              justify-content: space-between;
              margin-bottom: 12px;
              img {
                width: 100px;
                height: 100px;
                flex-shrink: 0;
                margin-right: 20px;
              }
              .goods_details {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                line-height: 20px;
                justify-content: space-between;
                .goods_name {
                  font-size: 16px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
                > div {
                  display: flex;
                  text-align: end;
                  flex-direction: column;
                  span {
                    color: #666;
                  }
                  // .brand_name {
                  // }
                  // .specification {
                  // }
                  // .unit_price {
                  // }
                  .actual_payment {
                    color: #000;
                  }
                }
              }
            }
            .goods_box:last-child {
              margin-bottom: 0;
            }
          }
          .goods::after {
            content: "";
            width: 150px;
            height: 150px;
            position: absolute;
            top: 50%;
            margin-top: -75px;
            right: 30px;
            background-image: url("../../assets/images/gz.png");
            background-size: 100%;
            background-repeat: no-repeat;
          }
          .totalAmount {
            border-top: 1px dashed #d9e5f8;
            // padding: 20px 0;
            padding: 8px 0;
            li {
              display: flex;
              justify-content: space-between;
              // margin-bottom: 12px;
              margin-bottom: 10px;
              color: #666;
              p {
                color: #000;
              }
            }
            li:last-child {
              margin-bottom: 0;
            }
          }
          .van-loading {
            text-align: center;
            padding: 30px 0;
          }
        }
        .order_footer {
          width: 100%;
          height: 12px;
          background-image: url("../../assets/images/single_oster_bottom.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }

  footer {
    position: absolute;
    height: 50px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .van-button {
      width: 80px;
      height: 38px;
      padding: 0;
      margin-left: 12px;
      border-color: #c9c9c9;
    }
  }
  .van-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    .img_box {
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 100px;
      overflow-y: scroll;
      text-align: center;
      img {
        width: 100%;
      }
    }
    .bottom {
      position: absolute;
      bottom: 25px;
      color: #fff;
      p {
        font-size: 18px;
        margin-bottom: 15px;
      }
      .close {
        width: 25px;
        height: 25px;
        border: 1px solid #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
      }
    }
  }
}
</style>
